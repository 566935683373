import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Container =  styled.div`
 max-Width: 960px;
 min-Width: 350px;
 margin: 0 auto;
 padding 110px 40px;
`;
const Line =  styled.hr`
margin: 80px 0 40px;
`;
const About = () => (
  <Layout>
    <SEO title="About" />
    <Container>
      <h1>Info</h1>
      <h3>Una empresa creada con el proposito de gestionar las marcas <Link to="https://www.animaespacio.org">Ánima Espacio Cultural</Link>, <Link to="https://www.booksonwall.art">BooksOnWall</Link> y <Link to="https://www.p113.io">P113</Link>.</h3>
      <p>Fundamos esta sociedad con el fin de crear proyectos con arte y tecnología. Queremos colaborar en el empoderamiento tecnológico e generara impacto social positivo. Somos un equipo de creativos con un espíritu cultural y activista.</p>
      <br />
      <h2>Equipo</h2>
      <p>Nuestro grupo esta compuesto por:</p>
      <ul>
        <li>Sofía Casanova, Co fundandor</li>
        <li>Sebastian Garcia, Co fundandor</li>
        <li>Javiera Torres, Co fundandor</li>
        <li>Tom Bouillut, Co fundandor</li>
        <li>Cristóbal Severin, Co fundandor</li>
      </ul>
      <br />
      <h2>Contacto</h2>
      <p>Encuentranos o comunicate con nosotros:</p>
      <p><b>Direción:</b> <Link to="https://www.openstreetmap.org/node/944838742#map=19/-34.91024/-56.17813">Magallanes 1064, Palermo, Montevideo, Uruguay.</Link></p>
      <p><b>Teléfono:</b> +598 098 682 058</p>
      <p><b>Chat:</b><Link to="/"> Telegram</Link> o <Link to="/"> WathsApp</Link> </p>
      <Line />
      <Link to="/">Volver</Link>
  </Container >
  </Layout>
)

export default About
